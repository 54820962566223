(function () {

    // Attach the change event listener to change the label of all input[type=file] elements
    var els = document.querySelectorAll("input[type=file]"),
            i;
    for (i = 0; i < els.length; i++) {
        els[i].addEventListener("change", function () {
            var label = this.previousElementSibling;
//            label.innerHTML = this.files[0].name;
        });
    }

})();

/**
 * Preview logo before upload
 */
$("#company_logo").on('change', function () {
    if (this.files && this.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            $('.thumbnail').attr('src', e.target.result);
        }
        reader.readAsDataURL(this.files[0]);
        $('input[name=existent_company_logo]').val('');
    }
}
);

/** Logout **/
$('.onLogout').click(function () {
    $.request('onLogout');
});

/**
 *  Reset logo in company-details
 */
$('a.button.cancel').on('click', function () {
    var $el = $('input[name=company_logo]');
    $el.wrap('<form>').closest('form').get(0).reset();
    $el.unwrap();
    $('.thumbnail').attr('src', '/themes/andb/assets/dist/img/image_square_placeholder.png');
    $('input[name=existent_company_logo]').val('');
});

/**
 * Check upload file size
 */
$('#attachments').on('change', function (e) {
    for (i = 0; i < e.target.files.length; ++i) {
        if ((e.target.files[i].size / 1024 / 1024) > 5) {
            $.oc.flashMsg({
                'text': 'Il file ' + e.target.files[i].name + ' è troppo grande: per favore usa files più piccoli di 5Mb',
                'class': 'error',
                'interval': 5
            });
            $('#attachments').val('');
        }
    }
});

/**
 * @param {string} whichField
 * @returns {boolean} false if no items are found (TBA)
 */
function addField(itemClassName) {

    /** TODO: refactor, remove unused vars and code **/

    // count items
    classNumber = $('.' + itemClassName).length;

    // add new cell with input fields
    $('.' + itemClassName + ':last').clone().insertAfter('.' + itemClassName + ':last');

    // empty input fields
    $('.' + itemClassName + ':last input').val('');

    // increment name attribute

    fullActualName = $('.' + itemClassName + ':last input').attr('name');

    itemNumber = parseInt(fullActualName.match(/\d+$/), 10);

    txtPart = fullActualName.substring(0, fullActualName.length - itemNumber.toString().length);

    // for every cell
    $('.' + itemClassName).each(function (index) {

        index += 1; // starts from 1

        // find elements with "name" attribute
        thisNameAttr = $(this).find('[name]');

        // for every element in cell with "name" attribute
        thisNameAttr.each(function () {

            // get name attribute
            actualAttributeName = $(this).attr('name');

            // get numeric part
            numericPart = actualAttributeName.match(/\d+/);

            // remove numeric part
            txtPart = actualAttributeName.substring(0, actualAttributeName.length - numericPart.toString().length);

            // set new attribute name
            $(this).attr('name', txtPart + index);
        });

    });


}

/**
 * @param {string} whichField
 * @returns {boolean} false if no items are found (TBA)
 */
function removeField(whichElement, itemClassName) {

    /** TODO: refactor, remove unused vars and code **/

    // retrieve actual number of repeater elements
    actualNumberOfElements = $('.' + itemClassName).length;

    // remove item
    whichElement.closest('.' + itemClassName).remove();

    // recalculate number of items
    actualNumberOfElements = $('.' + itemClassName).length;

    // reassign correct names to items
    // get base string
    baseString = whichElement.closest('.' + itemClassName).find('[name]').attr('name');
    // get numeric part
    numericPart = baseString.match(/\d+/);
    // remove numeric part
    newBaseString = baseString.replace(numericPart, "");

    // for every cell
    $('.' + itemClassName).each(function (index) {

        index += 1; // starts from 1

        // find elements with "name" attribute
        thisNameAttr = $(this).find('[name]');

        // for every element in cell with "name" attribute
        thisNameAttr.each(function () {

            // get name attribute
            actualAttributeName = $(this).attr('name');

            // get numeric part
            numericPart = actualAttributeName.match(/\d+/);

            // remove numeric part
            txtPart = actualAttributeName.substring(0, actualAttributeName.length - numericPart.toString().length);

            // set new attribute name
            $(this).attr('name', txtPart + index);
        });

    });

//    $('.' + itemClassName).each(function (index) {
//        index += 1; // starts from 1
//        $(this).find('[name]').attr('name', newBaseString + index);
//    });


}

document.addEventListener("DOMContentLoaded", ready);

function ready() {
    var scrollToTopBtn = document.querySelector(".scrollToTopBtn")
//    console.log('scrollToTopBtn:', scrollToTopBtn)
    var rootElement = document.documentElement

    function handleScroll() {
        // Do something on scroll
        var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
        if ((rootElement.scrollTop / scrollTotal) > 0.80) {
            // Show button
            console.log('scrolled')
            scrollToTopBtn.classList.remove("hideBtn")
            scrollToTopBtn.classList.add("showBtn")
        } else {
            // Hide button
            scrollToTopBtn.classList.add("hideBtn")
            scrollToTopBtn.classList.remove("showBtn")
        }
    }
    function scrollToTop() {
        // Scroll to top logic
        rootElement.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    scrollToTopBtn.addEventListener("click", scrollToTop)
    document.addEventListener("scroll", handleScroll)
}



